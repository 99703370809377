<template>
  <div class="inner-section application-form-wrapper">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenService.country_wise_import') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-overlay :show="loading">
            <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
              <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Year" vid="year" rules="required|min_value:1">
                    <b-form-group label-for="year" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="year"
                        v-model="formData.year"
                        :options="yearList"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Month" vid="month" rules="required|min_value:1">
                    <b-form-group label-for="month" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('globalTrans.month') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="month"
                        v-model="formData.month"
                        :options="monthList"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Country" vid="country_id" rules="required|min_value:1">
                    <b-form-group label-for="country_id" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('globalTrans.country') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="country_id"
                        v-model="formData.country_id"
                        :options="countryList"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <div class="add-more-area border-top border-dark pt-3">
                <b-row>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Made Tea Type" vid="tea_type_id" rules="">
                      <b-form-group label-for="tea_type_id" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('teaGardenConfig.made_tea_type') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          id="tea_type_id"
                          v-model="formDataAddMore.tea_type_id"
                          :options="masterMadeTeaTypeList"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Quantity" vid="quantity" rules="">
                      <b-form-group label-for="quantity" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('teaGardenService.quantity') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="quantity"
                          v-model="formDataAddMore.quantity"
                          :state="errors[0] ? false : (valid ? true : null)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keypress="isNumber"
                          min="0"
                        >
                        </b-form-input>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" class="text-right">
                    <b-button type="button" size="sm" class="btn btn-success" @click="addItem">
                      <i class="ri-add-circle-fill"></i> {{ $t('globalTrans.add_more') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-table-simple bordered hover small responseive class="mt-3">
                  <b-thead>
                    <b-tr>
                      <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                      <b-th>{{ $t('teaGardenConfig.made_tea_type') }}</b-th>
                      <b-th>{{ $t('teaGardenService.quantity') }}</b-th>
                      <b-th>{{ $t('globalTrans.action') }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <template v-if="formData.details.length">
                      <b-tr v-for="(item, index) in formData.details" :key="index">
                        <b-td>{{ $n(index + 1) }}</b-td>
                        <b-td>{{ getTeaTypeName(item.tea_type_id) }}</b-td>
                        <b-td>
                          <ValidationProvider name="Quantity" vid="quantity" rules="required|min_value:0">
                            <b-form-group label-for="quantity" slot-scope="{ valid, errors }">
                              <b-form-input
                                id="quantity"
                                v-model="item.quantity"
                                :state="errors[0] ? false : (valid ? true : null)"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                @keypress="isNumber"
                                min="0"
                              >
                              </b-form-input>
                              <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-td>
                        <b-td>
                          <b-button type="button" size="sm" class="btn btn-danger" @click="deleteItem(index)">
                            <i class="ri-close-line"></i>
                          </b-button>
                        </b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td class="text-center" colspan="4">{{ $t('globalTrans.noDataFound') }}</b-td>
                      </b-tr>
                    </template>
                    <b-tr>
                      <b-td colspan="2"><strong>{{ $t('globalTrans.grand_total') }}</strong></b-td>
                      <b-td class="text-right"><strong>{{ $n(getTotalQty()) }}</strong></b-td>
                      <b-td></b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
              <b-row class="text-right mt-3">
                <b-col>
                    <b-button type="submit" variant="warning" class="mr-2 btn-sm" @click="formData.status = 2">{{ $t('globalTrans.draft') }}</b-button>
                    <b-button type="submit" variant="success" class="mr-2 btn-sm" @click="formData.status = 1">{{ $t('globalTrans.save') }}</b-button>
                    <b-button variant="danger" class="btn-sm" @click="back">{{ $t('globalTrans.cancel') }}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-overlay>
        </ValidationObserver>
      </template>
    </body-card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { countryWiseImportStore, countryWiseImportUpdate, countryWiseImportShow } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
import BreadCumb from '@/components/BreadCumb.vue'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  name: 'Form',
  components: { BreadCumb },
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        country_id: 0,
        status: 2,
        details: []
      },
      formDataAddMore: {
        tea_type_id: 0,
        quantity: 0
      }
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    masterBoughtLeafFactoryList () {
      return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.status === 1)
    },
    masterMadeTeaTypeList () {
      return this.$store.state.TeaGardenService.commonObj.masterMadeTeaTypeList.filter(item => item.status === 1)
    },
    yearList () {
      return helpers.getYearList()
    },
    monthList () {
      return this.$store.state.commonObj.monthList.map(item => {
        return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
      })
    },
    countryList () {
      return this.$store.state.CommonService.commonObj.countryList.filter(item => item.status === 1 && (item.text_en.toLowerCase() !== 'bangladesh'))
    }
  },
  watch: {
  },
  created () {
    if (this.$route.query.id) {
      this.getFormData()
    }
  },
  methods: {
    async getFormData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, countryWiseImportShow + '/' + this.$route.query.id)
      if (result.success) {
        this.formData = result.data
      } else {
        this.formData = {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1,
          country_id: 0,
          status: 2,
          details: []
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    async saveUpdate () {
      if (!this.formData.details.length) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Please check at least one stock item'
        })
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadingState = { loading: false, listReload: false }
      let result
      if (this.formData.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, countryWiseImportUpdate + '/' + this.formData.id, this.formData)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, countryWiseImportStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$router.push('country-wise-import')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    back () {
      this.$router.go(-1)
    },
    getTeaTypeName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterMadeTeaTypeList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    addItem () {
      if (!(this.formDataAddMore.tea_type_id && this.formDataAddMore.quantity)) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Please select tea type and transfer quantity'
        })
      }
      const obj = this.formData.details.find(item => item.tea_type_id === this.formDataAddMore.tea_type_id)
      if (obj !== undefined) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'This tea type already exists'
        })
      }
      this.formData.details.push(this.formDataAddMore)
      this.formDataAddMore = {
        tea_type_id: 0,
        quantity: 0,
        remarks: ''
      }
    },
    deleteItem (index) {
      this.formData.details.splice(index, 1)
    },
    getTotalQty () {
      let totalQty = 0
      this.formData.details.forEach(item => {
        totalQty += parseFloat(item.quantity)
      })
      return totalQty
    }
  }
}
</script>
